export const headerMenuItems = [
  {
    link: '',
    label: 'Home',
  },
  {
    link: 'blog',
    label: 'Blog',
  },
  {
    link: 'projects',
    label: 'Projects',
  },
  {
    link: 'resume',
    label: 'Resume',
  },
];
